$primary: black;
$secondary: #38C098;
$info:          #E6E5E3 !default;
$dark:          #000 !default;
// $success:       $green !default;
// $warning:       $yellow !default;
// $danger:        $red !default;
// $light:         $gray-100 !default;

@import "../node_modules/bootstrap/scss/bootstrap";

* {
  font-family: "Century Gothic", sans-serif;
}

h1, .h1 { font-size: 2.75rem; }
h2, .h2 { font-size: 2.25rem; }
h3, .h3 { font-size: 2rem; }
h4, .h4 { font-size: 1.75rem; }
h5, .h5 { font-size: 1.55rem; }
h6, .h6 { font-size: 1.25rem; }
body { font-size: 1.05rem; }

h1, h1 *,
h2, h2 *,
h3, h3 *,
h4, h4 *,
h5, h5 *,
h6, h6 *,
.font-title {
  font-family: "Century Gothic", sans-serif;
  line-height: 1.5em;
}

.font-title {
  line-height: 2em;
}

blockquote {
  border-left: none!important;
  padding-left: 0!important;
  font-size: 1.2em;
}

// site header

nav.navbar h2 a {
  text-decoration: none;
}

@media (min-width: 767px) {
  #site-header {
    img {
      padding-left: 0 !important;
    }
  }
}

// site footer

#contact {
  a {
    color: white;
  }
}

// splash

#splash {
  height: 400px;
  overflow: hidden;
  position: relative;

  #splash-image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    object-fit: cover;
  }

  .container {
    position: relative;
    height: 400px;
  }

  .row {
    position: absolute;
    bottom: 10px;
  }

  p {
    color: white;
    font-size: 25px;
    font-weight: bold;
  }
}

@media (min-width: 767px) {
  #splash,
  #splash img,
  #splash .container {
    height: 800px;
  }

  #splash .row {
    bottom: 50px;
  }

  #splash p {
    font-size: 40px;
  }
}

// services images (home page)

.services-image-header {
  background-color: black;
  padding: 10px;

  p {
    color: white;
    text-align: center;
    margin-bottom: 0;
    font-weight: bold;
  }
}

//

.carousel img {
  object-fit: contain;
  width: 100%;
  height: 750px;
}

blockquote {
  border-left: 2px solid #003b64;
  padding-left: 15px;
  margin: 20px 0;
}

.text-underline {
  text-decoration: underline;
}

.service-image {
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 767px) {
  .service-image {
    height: 600px;
  }
}



.card-img-top {
  position: relative;
  overflow: hidden;
}

.zoom-effect {
  transition: transform 0.3s ease;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.zoom-effect:hover {
  transform: scale(1.05);
}